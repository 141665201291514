.reel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    height: 500px;
    /* border: 1px solid black; */
    border-radius: 5px;
    margin: 0 10px;
}

.spin {
    animation: roll 2.5s linear normal infinite;
}

.loading {
    animation: roll 2.5s linear normal infinite;
}

.slot-machine__slot-item {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

@keyframes roll {  /* This is the animation that will be applied to the reel */
    0% {
        transform: translateY(-6000%);
    }
    100% {
        transform: translateY(0%);
    }
}