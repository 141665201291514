.slot-machine {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    margin-top: 110px;
}

.slot-machine__title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #ccc;
}

.slot-machine__slot {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slot-machine__reels {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slot-machine__controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
}

.slot-machine__winning-lines {
    position: relative;
}

.slot-machine__winning-line1 {
    position: absolute;
    width: 600px;
    height: 0;
    border: 3px solid rgba(225, 25, 202, 0.7);
    z-index: 1;

    top: -200px;
    display: none;
}

.slot-machine__winning-line2 {
    position: absolute;
    width: 600px;
    height: 0;
    border: 3px solid rgba(199, 28, 28, 0.7);
    z-index: 1;

    top: -100px;
    display: none;
}

.slot-machine__winning-line3 {
    position: absolute;
    width: 600px;
    height: 0;
    border: 3px solid rgba(59, 178, 22, 0.7);
    z-index: 1;

    top: 0;
    display: none;
}

.slot-machine__winning-line4 {
    position: absolute;
    width: 600px;
    height: 0;
    border: 3px solid rgba(42, 40, 192, 0.7);
    z-index: 1;

    top: 100px;
    display: none;
}

.slot-machine__winning-line5 {
    position: absolute;
    width: 600px;
    height: 0;
    border: 3px solid rgba(159, 177, 15, 0.7);
    z-index: 1;

    top: 200px;
    display: none;
}

.show {
    display: block;
}

.fade-out {
    animation: fadeOut 2s linear;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.slot-machine__button {
    width: 180px;
    height: 35px;
    margin: 10px;
    display: block;
    background-color: #00663b00;
    background-image: url(../../images/button-normal.png);
    background-size: 100%;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.slot-machine__button:hover {
    /* background-color: #004d2b; */
    background-image: url(../../images/button-hover.png);
}

.slot-machine__button:active {
    /* background-color: #003135; */
    background-image: url(../../images/button-hover.png);
}

.slot-machine__reels__overlay {
    position: absolute;
    width: 500px;
    height: 100px;
    z-index: 99;
}

.slot-machine__reels__overlay__text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    opacity: 0;
    color: rgb(240, 220, 0);
    z-index: 2;

    font-family: "Arial Black", sans-serif;
    font-size: 3em;
    text-shadow: 3px 3px rgb(29, 22, 22);
    text-transform: uppercase;
    letter-spacing: 0.03em;
    text-align: center;
    font-weight: bold;
}

.slot-machine__reels__overlay__text--win {
    animation: pop-up 1.5s ease-in-out;
    opacity: 0;
}

.slot-machine__reels__overlay__text--big-win {
    animation: pop-up 3s ease-in-out;
    opacity: 0;
}

.slot-machine__reels__overlay__text--mega-win {
    animation: pop-up 5s ease-in-out;
    opacity: 0;
}

.slot-machine__reels__overlay__text--jackpot-win {
    animation: pop-up 5s ease-in-out;
    opacity: 0;
}
  
@keyframes pop-up {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(6);
        opacity: 0;
    }
}

/* winnings table */
table.winnings-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.2em;
    text-align: center;
  }
  
  table.winnings-table th,
  table.winnings-table td {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  table.winnings-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  table.winnings-table td:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  table.winnings-table td:nth-child(odd) {
    background-color: #fff;
  }



  @media only screen and (max-width: 767px) {
    /* Mobile view */
  }