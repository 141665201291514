.popup {
    /* position and dimension */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    /* overlay background */
    background-color: rgba(0, 0, 0, 0.5);
    
    /* center the popup */
    display: grid;
    align-items: center;
    justify-content: center;
    
    /* make the popup visible */
    visibility: visible;
    opacity: 1;
    z-index: 999;
  
    /* add scrolling */
    overflow: auto;
  }

  .popup .header {
    /* position and dimension */
    position: relative;
    width: 100%;
    height: 50px;
    
    /* background */
    background-color: lightgray;
    
    /* center the header */
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .popup .header button {
    width: 50px;
    height: 50px;
    margin: 0;
    display: block;
    background-color: #00663b;
    color: #fff;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
}

.popup .header button:hover {
    background-color: #004d2b;
}

.popup .header button:active {
    background-color: #003135;
}
    
  /* style the table inside the popup */
  .popup table {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
  }
  
  .popup th, .popup td {
    border: 1px solid gray;
    padding: 0.5em;
  }
  
  .popup th {
    text-align: left;
    background-color: lightgray;
  }